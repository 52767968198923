import React from 'react';
import Header from './Header';
import Footer from './Footer';
import antoinetteImage from './antoinette.jpg';
import clearpowerLogo from './clearpower.png';
import './Antoinette.css';

function Antoinette() {
  return (
    <div className="contact-page">
      <main className="contact-main">
        <div className="contact-container">
          <div className="img-container">
            <img src={antoinetteImage} className="image"></img>
          </div>
          <h2>Antoinette Pickette</h2>
          <p>EV Charging Solutions Specialist at ClearPower</p>
          <div className="address">
          <p>980 W. Coast Highway, 200, Newport Beach, CA 92663</p>
          </div>
          <div className="contact-buttons">
            <a href="tel:+19097758017" className="contact-button"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#2f4f4f"><path d="M760-480q0-117-81.5-198.5T480-760v-80q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480h-80Zm-160 0q0-50-35-85t-85-35v-80q83 0 141.5 58.5T680-480h-80Zm198 360q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/></svg> <b>(909) 775-8017</b></a>
            <a href="mailto:antoinette@clearpowerusa.com" className="contact-button"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#2f4f4f"><path d="m720-160-56-56 63-64H560v-80h167l-63-64 56-56 160 160-160 160ZM160-280q-33 0-56.5-23.5T80-360v-400q0-33 23.5-56.5T160-840h520q33 0 56.5 23.5T760-760v204q-10-2-20-3t-20-1q-10 0-20 .5t-20 2.5v-147L416-520 160-703v343h323q-2 10-2.5 20t-.5 20q0 10 1 20t3 20H160Zm58-480 198 142 204-142H218Zm-58 400v-400 400Z"/></svg> <b>antoinette@clearpowerusa.com</b></a>
          </div>
          <div className="divider"> </div>
          <div className="contact-buttons">
          <a href="https://www.clearpowerusa.com" className="clearpower-button"><img src={clearpowerLogo} alt="ClearPower Logo" className="logo-image"/></a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Antoinette;
