import React from 'react';
import './Footer.css';
import footerLogo from './IMG_8093.png'; // Ensure the path is correct

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={footerLogo} alt="ClearPower Logo" />
          </div>
          <div className="footer-contact">
            <h4>Contact Us</h4>
            <p>Email: <a href="mailto:info@clearpowerusa.com">info@clearpowerusa.com</a></p>
            <p>Phone: <a href="tel:+19493552257">(888) 725-1755</a></p>
            <p>Address 1: 980 W. Coast Highway, 200, Newport Beach, CA 92663</p>
            <p>Address 2: 1100 S Grove Ave Building E-2, Suite 6, Ontario, CA 91761</p>
          </div>
          <div className="footer-legal">
            <ul className="footer-links">
              <li><a href="/terms-of-service">Terms of Service</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/cookie-policy">Cookie Policy</a></li>
            </ul>
            <p>&copy; 2024 ClearPower USA, inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
