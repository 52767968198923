import React, { useEffect, useRef } from 'react';
import './Hero.css';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { Link } from 'react-router-dom';

function Hero() {
  const mountRef = useRef(null);

  useEffect(() => {
    if (!mountRef.current) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(50, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    camera.position.set(0, 0, 4);

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    mountRef.current.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 3);
    scene.add(ambientLight);

    const directionalLight1 = new THREE.DirectionalLight(0xffeedd, 8);
    directionalLight1.position.set(5, 10, 7.5);
    scene.add(directionalLight1);

    const directionalLight2 = new THREE.DirectionalLight(0xadd8e6, 4);
    directionalLight2.position.set(-5, 10, -7.5);
    scene.add(directionalLight2);

    const spotlight1 = new THREE.SpotLight(0xffffff, 1);
    spotlight1.position.set(0, 15, 10);
    spotlight1.angle = Math.PI / 6;
    spotlight1.penumbra = 0.1;
    spotlight1.decay = 2;
    spotlight1.distance = 200;
    spotlight1.castShadow = true;
    scene.add(spotlight1);

    const spotlight2 = new THREE.SpotLight(0xffffff, 1);
    spotlight2.position.set(0, 15, -10);
    spotlight2.angle = Math.PI / 6;
    spotlight2.penumbra = 0.1;
    spotlight2.decay = 2;
    spotlight2.distance = 200;
    spotlight2.castShadow = true;
    scene.add(spotlight2);

    const loader = new GLTFLoader();
    loader.load(`${process.env.PUBLIC_URL}/v2plusnew.glb`, (gltf) => {
      const model = gltf.scene;

      model.scale.set(1.5, 1.5, 1.5);
      model.position.set(0, -1.5, 0);
      model.rotation.set(Math.PI / 2, Math.PI, Math.PI / 2 - 5 * Math.PI / 36);

      scene.add(model);

      const animate = function () {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };

      animate();

      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        const scrollFraction = Math.min(scrollTop / maxScroll * 10, 1);
        const initialRotation = Math.PI / 2 - 5 * Math.PI / 32;
        const targetRotation = Math.PI / 2;
        model.rotation.z = initialRotation + scrollFraction * (targetRotation - initialRotation);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, undefined, (error) => {
      console.error('An error happened during loading the GLB model:', error);
    });

    const handleResize = () => {
      if (mountRef.current) {
        camera.aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (mountRef.current && mountRef.current.contains(renderer.domElement)) {
        mountRef.current.removeChild(renderer.domElement);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="hero">
      <div className="container hero-container">
        <div className="hero-content">
          <h1 className="hero-headline">Own Your Future with ClearPower</h1>
          <p className="hero-subheadline">Offering Unique EV Ownership Opportunities Unlike Any Other Company</p>
          <p className="hero-body-text">
            Join our exclusive program, invest in your own EV charging unit, and profit from your investment.
          </p>
          <div className="hero-buttons">
            <Link to="/contact">
  <button className="btn-primary">Get Started</button>
</Link>
          </div>
        </div>
        <div className="hero-image" ref={mountRef}></div>
      </div>
    </section>
  );
}

export default Hero;

