import React, { useEffect } from 'react';
import { animate } from '@motionone/dom';
import './AboutPage.css';
import missionImage from './AdobeStock_518959833.jpeg'; // Import the mission image
import visionImage from './amenities.png'; // Import the vision image
import LeftGreen from './leftgreen.png';
import { Link } from 'react-router-dom';

function AboutPage() {
  useEffect(() => {
    animate('.headline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1 });
    animate('.subheadline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 0.5 });
    animate('.intro-text', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 1 });
  }, []);

  return (
    <div className="about-page">
      {/* Header Section */}
      <section className="header-section">
        <div className="container">
          <h1 className="headline">About ClearPower</h1>
          <h2 className="subheadline">Pioneering the Future of EV Charging</h2>
          <p className="intro-text">
            At ClearPower, we are dedicated to revolutionizing the electric vehicle (EV) charging experience. Our mission is to provide high-tech, state-of-the-art charging warehouses that go beyond the basics of charging your vehicle. We offer a premium experience with luxurious amenities and innovative investment opportunities that set us apart in the industry.
          </p>

        </div>
      </section>

      {/* Vision Section */}
      <section className="vision-section">
        <div className="container">
          <div className="vision-content">
            <div className="vision-text">
              <h3 className="dkgreen">Our Vision</h3>
              <p className="ltgreen">
                We envision a future where EV charging is not just a necessity but an enjoyable experience. By integrating comfortable lounges, business centers, gaming areas, and cafes into our charging stations, we aim to create spaces where people can relax, work, and have fun while their vehicles charge.
              </p>
            </div>
            <div className="vision-image">
              <img src={visionImage} alt="People enjoying ClearPower amenities" />
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="mission-section">
        <div className="container">
          <div className="mission-content">
            <div className="mission-image">
              <img src={missionImage} alt="ClearPower mission" />
            </div>
            <div className="mission-text">
              <h3 className="dkgreen">Our Mission</h3>
              <p className="ltgreen">
                Our mission is to make EV charging accessible, efficient, and profitable for everyone. We believe in a sustainable future, and by enabling individuals to invest in our cutting-edge infrastructure, we are democratizing the EV industry and promoting green energy solutions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="story-section">
        <div className="container">
          <h3 className="dkgreen">Our Story</h3>
          <p className="ltgreen">
            ClearPower was founded with a vision to revolutionize the way people charge their electric vehicles and enjoy entertainment. We started with a focus on innovation and sustainability. 
          </p>
          <p className="ltgreen">
            We are currently working on building our first state-of-the-art EV charging warehouse, which will provide a premium charging experience with amenities like lounges, business centers, and cafes. Our integrated app will allow users to accumulate points by engaging with ads on the chargers' digital displays.
          </p>
          <p className="ltgreen">
            In addition to our charging warehouses, we are expanding our services to include Dome entertainment parks, offering a unique blend of advanced EV charging and immersive entertainment experiences.
          </p>
          <p className="ltgreen">
            Our integrated network, ALMAX, will provide seamless integration and personalized services, enhancing the EV charging experience with scheduling, progress tracking, rewards, and cryptocurrency payments.
          </p>
          <p className="ltgreen">
            We are committed to ensuring security with advanced measures, including 24/7 surveillance, on-site security personnel, advanced alarm systems, and reinforced structures.
          </p>
        </div>
      </section>
      <div className="darkgreenbreak"></div>
      <section className="cta-block">
      <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Revolutionize the EV charging experience.</h3>
          <a href="/contact" className="btn-primary">Contact Us</a>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </section>

         </div>
  );
}

export default AboutPage;
