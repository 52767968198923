import React, { useState, useEffect } from 'react';
import './HowItWorks.css';
import titleImage from './titleimage.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function HowItWorks() {
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrMobile(window.innerWidth <= 996);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const icons = document.querySelectorAll('.circle-icon');
    icons.forEach(icon => {
      gsap.fromTo(icon, { opacity: 0 }, {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: icon,
          start: 'top bottom',
          toggleActions: 'play none none reverse',
        },
      });
    });
  }, []);

  return (
    <div className="how-it-works-page">
      <section className="how-it-works-container">
        <div className="darkgreenbreak"><p></p></div>
        <div className="section-title" style={{ titleImage: `url(${titleImage})` }}>
          <h2>Investment Process</h2>
        </div>
        <div className="darkgreenbreak"><p></p></div>
        <div>
          <div className="whitebreak"><p></p></div>
        </div>
        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-graph-up-arrow circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Select Your Investment</h3>
            <p className="dkgreen">Choose between V2+ or V3 units.</p>
          </div>
        </div>

        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-lock-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Secure Your Spot</h3>
                <p className="dkgreen">Work with our team to secure financing and apply for government grants.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Secure Your Spot</h3>
                <p className="dkgreen">Work with our team to secure financing and apply for government grants.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-lock-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-briefcase-fill circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Invest in Equipment</h3>
            <p className="dkgreen">Contribute to the equipment required for your chosen unit.</p>
          </div>
        </div>

        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-pie-chart-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Profit Sharing</h3>
                <p className="dkgreen">Earn a share of the profits generated by your investment.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Profit Sharing</h3>
                <p className="dkgreen">Earn a share of the profits generated by your investment.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-pie-chart-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-bar-chart-fill circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Monitor Your Earnings</h3>
            <p className="dkgreen">Use our app to track your profits and stay updated on the performance of your investment.</p>
          </div>
        </div>

        <div className="section-padding">
        </div>
      </section>
    </div>
  );
}

export default HowItWorks;
