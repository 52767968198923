import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Cory.css';

function Gary() {
  return (
    <div className="contact-page">
      <main className="contact-main">
        <div className="contact-container">
          <h2>Gary Gao</h2>
          <p>Marketing Director and Sourcing Manager at ClearPower</p>
          <div className="contact-buttons">
            <a href="tel:+19497278015" className="contact-button">Call Me: (949) 727-8015</a>
            <a href="tel:+16263441632" className="contact-button">Cell: (626) 344-1632</a>
            <a href="mailto:gary@clearpowerusa.com" className="contact-button">Email Me: gary@clearpowerusa.com</a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Gary;
