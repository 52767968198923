import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Cory.css';

function Cory() {
  return (
    <div className="contact-page">
      <main className="contact-main">
        <div className="contact-container">
          <h2>Cory Spelde</h2>
          <p>Creative Director at ClearPower</p>
          <div className="contact-buttons">
            <a href="tel:+19492334388" className="contact-button">Call Me: (949) 233-4388</a>
            <a href="mailto:cory@clearpowerusa.com" className="contact-button">Email Me: cory@clearpowerusa.com</a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Cory;
