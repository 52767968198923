import React, { useEffect } from 'react';
import { animate } from '@motionone/dom';
import './TechnologyPage.css';
import headerImage from './slide2.png'; // Import the new header image
import AlmaxLogo from './almax.png';
import AlmaxMap from './almaxmap.png';
import AlmaxSplash from './almaxsplash.png';
import AlmaxWallet from './almaxwallet.png';
import { Link } from 'react-router-dom';
import LeftGreen from './leftgreen.png';

function TechnologyPage() {
  useEffect(() => {
    const elements = document.querySelectorAll('.icon, .animation');
    elements.forEach((el, index) => {
      animate(el, { opacity: [0, 1], y: [-20, 0] }, { duration: 1, delay: index * 0.3 });
    });

    animate('.headline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1 });
    animate('.subheadline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 0.5 });
    animate('.main-copy', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 1 });
  }, []);

  return (
    <div className="technology-page">
      {/* Header Section */}
      <section className="header-section" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="container">
          <h1 className="headline">Our Advanced Technology</h1>
          <h2 className="subheadline">Pioneering the Future of EV Charging</h2>
          <p className="main-copy">
            ClearPower is at the forefront of EV charging technology, offering the most advanced and efficient solutions for electric vehicles. Our commitment to innovation ensures that our charging stations are not only fast but also sustainable and user-friendly.
          </p>
        </div>
      </section>

      {/* Key Technologies Section */}
      <section className="key-technologies-section">
        <div className="container">
          <h3>Key Technologies</h3>
          <div className="technologies-grid">
            <div className="technology">
              <div className="icon"><i className="fas fa-bolt"></i></div>
              <p className="description">Experience ultra-fast charging with our V3 superchargers, capable of delivering up to 250 kW of power.</p>
              <div className="animation"></div>
            </div>
            <div className="technology">
              <div className="icon"><i className="fas fa-solar-panel"></i></div>
              <p className="description">Our stations are integrated with solar panels and other renewable energy sources to reduce our carbon footprint.</p>
              <div className="animation"></div>
            </div>
            <div className="technology">
              <div className="icon"><i className="fas fa-microchip"></i></div>
              <p className="description">Our smart charging systems optimize energy usage and ensure your vehicle charges efficiently.</p>
              <div className="animation"></div>
            </div>
            <div className="technology">
              <div className="icon"><i className="fas fa-mobile-alt"></i></div>
              <p className="description">Connect to our network with the ClearPower app for real-time monitoring and management of your charging sessions.</p>
              <div className="animation"></div>
            </div>
            <div className="technology">
              <div className="icon"><i className="fas fa-tv"></i></div>
              <p className="description">Easy-to-use touchscreens at our stations provide clear instructions and support multiple languages.</p>
              <div className="animation"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Almax App Section */}
      <section className="app-section">
      <div className="container">
        <img src={AlmaxLogo} alt="Almax Logo" className="app-logo" />
        <p className="description">Manage your EV charging experience with our intuitive mobile app. Locate charging stations, monitor your charge, earn rewards, and much more.</p>
        <div className="app-images">
          <div className="app-image-container">
            <img src={AlmaxMap} alt="Almax Map" className="app-image" />
          </div>
          <div className="app-image-container">
            <img src={AlmaxSplash} alt="Almax Splash" className="app-image" />
          </div>
          <div className="app-image-container">
            <img src={AlmaxWallet} alt="Almax Wallet" className="app-image" />
          </div>
        </div>
        <div className="app-info">
          <div className="app-info-section">
            <h4>Elevated Charging Experience</h4>
            <p>Our app transforms EV charging into a rewarding journey, offering points, rewards, and gaming. With a sleek interface akin to tech giants, we redefine the charging experience.</p>
          </div>
          <div className="app-info-section">
            <h4>Seamless Integration</h4>
            <p>Users can easily earn, exchange, and pay for charging with cryptocurrencies, alongside traditional fiat methods. This flexibility sets a new standard for the industry.</p>
          </div>
          <div className="app-info-section">
            <h4>Personalized Services & Community</h4>
            <p>From scheduling services to tracking progress and accessing exclusive rewards, our app fosters a vibrant EV community, elevating safety and innovation.</p>
          </div>
        </div>
      </div>
    </section>

      {/* Call to Action Section */}
      <div className="darkgreenbreak"></div>
      <div className="cta-block">
        <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Revolutionize the EV charging experience.</h3>
          <Link to="/contact">
  <button className="btn-primary">Contact Us</button>
</Link>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </div>
          </div>
  );
}

export default TechnologyPage;
