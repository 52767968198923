import React, { useEffect } from 'react';
import './ServicesPage.css';
import headerImage from './AdobeStock_377636896.jpeg'; // Import the new header image
import EVWarehouseVideo from './EVWarehousePresentation - Made with Clipchamp.mp4';
import DomeVideo from './domepresentationfinal.mp4';
import AlmaxLogo from './almax.png';
import AlmaxMap from './almaxmap.png';
import AlmaxSplash from './almaxsplash.png';
import AlmaxWallet from './almaxwallet.png';
import { animate } from '@motionone/dom';
import { Link } from 'react-router-dom';
import LeftGreen from './leftgreen.png';

function ServicesPage() {
  useEffect(() => {
    animate('.headline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1 });
    animate('.subheadline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 0.5 });
    animate('.main-copy', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 1 });
  }, []);

  return (
    <div className="services-page">
      {/* Header Section */}
      <section className="header-section" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="container">
          <h1 className="headline">Our Premium Services</h1>
          <h2 className="subheadline">Enhancing Your EV Charging Experience</h2>
          <p className="main-copy">
            At ClearPower, we go beyond providing state-of-the-art EV charging solutions. Our premium services are designed to enhance your charging experience with comfort, convenience, and entertainment.
          </p>
        </div>
      </section>

      {/* Key Services Section */}
      <section className="key-services-section">
        <div className="container">
          <h3>Key Services</h3>
          <div className="services-grid">
            <div className="service">
              <div className="icon"><i className="fas fa-bolt"></i></div>
              <p className="description">Get back on the road quicker with our cutting-edge charging technology, capable of charging your EV in minutes.</p>
            </div>
            <div className="service">
              <div className="icon"><i className="fas fa-chair"></i></div>
              <p className="description">Relax in our comfortable lounges equipped with Wi-Fi, seating, and refreshments.</p>
            </div>
            <div className="service">
              <div className="icon"><i className="fas fa-briefcase"></i></div>
              <p className="description">Stay productive while your vehicle charges with access to workspaces, printers, and high-speed internet.</p>
            </div>
            <div className="service">
              <div className="icon"><i className="fas fa-gamepad"></i></div>
              <p className="description">Enjoy a variety of games and entertainment options to pass the time.</p>
            </div>
            <div className="service">
              <div className="icon"><i className="fas fa-coffee"></i></div>
              <p className="description">Grab a bite to eat or a coffee from our on-site cafes offering a range of snacks and beverages.</p>
            </div>
            <div className="service">
              <div className="icon"><i className="fas fa-mobile-alt"></i></div>
              <p className="description">Use our app to locate charging stations, monitor your charge, and earn rewards.</p>
            </div>
          </div>
        </div>
      </section>

      {/* EV Warehouse Video Section */}
      <section className="video-section">
        <div className="container">
          <h3>EV Charging Warehouses</h3>
          <p className="description">Experience our state-of-the-art EV charging warehouses, designed to provide a superior charging experience.</p>
          <div className="video-container">
            <video src={EVWarehouseVideo} autoPlay loop muted playsInline className="video">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Dome Video Section */}
      <section className="video-section">
        <div className="container">
          <h3>Dome Entertainment Parks</h3>
          <p className="description">Enjoy our Dome Entertainment Parks, offering a unique blend of fun and relaxation for the whole family.</p>
          <div className="video-container">
            <video src={DomeVideo} autoPlay loop muted playsInline className="video">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
      {/* Call to Action Section */}
      <div className="darkgreenbreak"></div>
      <div className="cta-block">
        <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Revolutionize the EV charging experience.</h3>
          <Link to="/contact">
  <button className="btn-primary">Contact Us</button>
</Link>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </div>
         </div>
         
  );
}

export default ServicesPage;
