import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Selena.css';

function Selena() {
  return (
    <div className="contact-page">
      <main className="contact-main">
        <div className="contact-container">
          <h2>Selena Patel</h2>
          <p>Vice President at ClearPower</p>
          <div className="contact-buttons">
            <a href="tel:+19099224302" className="contact-button">Call Me: (909) 922-4302</a>
            <a href="mailto:selena@clearpowerusa.com" className="contact-button">Email Me: selena@clearpowerusa.com</a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Selena;
