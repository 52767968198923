import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome
import 'aos/dist/aos.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import AlmaxLogo from './almax.png';
import AlmaxMap from './almaxmap.png';
import AlmaxSplash from './almaxsplash.png';
import AlmaxWallet from './almaxwallet.png';
import Footer from './Footer';

AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
 <Footer />
  </React.StrictMode>
);

reportWebVitals();
