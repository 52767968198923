import React, { useState, useEffect } from 'react';
import './HowItWorksPage.css';
import headerImage from './slide8.png'; // Import the new header image
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap icons
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { animate } from '@motionone/dom';
import { Link } from 'react-router-dom';
import LeftGreen from './leftgreen.png';

gsap.registerPlugin(ScrollTrigger);

function HowItWorksPage() {
  const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrMobile(window.innerWidth <= 996);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const icons = document.querySelectorAll('.circle-icon');
    icons.forEach(icon => {
      gsap.fromTo(icon, { opacity: 0 }, {
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: icon,
          start: 'top bottom',
          toggleActions: 'play none none reverse',
        },
      });
    });

    animate('.headline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1 });
    animate('.subheadline', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 0.5 });
    animate('.intro-text', { opacity: [0, 1], y: [-50, 0] }, { duration: 1, delay: 1 });
  }, []);

  return (
    <div className="how-it-works-page">
      {/* Header Section */}
      <section className="header-section" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="container">
          <h1 className="headline">How ClearPower Works</h1>
          <h2 className="subheadline">Simplifying the EV Charging and Investment Experience</h2>
          <p className="intro-text">
            At ClearPower, we streamline the EV charging process and offer unique investment opportunities in the rapidly growing electric vehicle (EV) industry. Here’s how it all works and how you can become an investor.
          </p>
        </div>
      </section>

      {/* Charging Your EV Section */}
      <section className="how-it-works-container">
      <div className="darkgreenbreak"></div>
        <div className="section-title">
          <h2 className="dkgreen">Charging Your EV</h2>
        </div>
        <div className="darkgreenbreak"></div>
        <div className="whitebreak"></div>
        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-geo-alt-fill circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Locate a Charging Station</h3>
            <p>Use our app to find the nearest ClearPower charging station.</p>
          </div>
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-plug-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Plug In and Charge</h3>
                <p>Connect your EV to our ultra-fast charging stations.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Plug In and Charge</h3>
                <p>Connect your EV to our ultra-fast charging stations.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-plug-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-cup-straw circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Relax and Enjoy</h3>
            <p>Enjoy our premium amenities while your vehicle charges.</p>
          </div>
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-phone-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Monitor Progress</h3>
                <p>Keep track of your charging status via our app.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Monitor Progress</h3>
                <p>Keep track of your charging status via our app.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-phone-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="section-padding"></div>
      </section>

      
      {/* Investment Process Section */}
      <section className="how-it-works-container">
      <div className="darkgreenbreak"></div>
        <div className="section-title">
          <h2>Investment Process</h2>
        </div>
        <div className="darkgreenbreak"></div>
        <div className="whitebreak"></div>
        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-graph-up-arrow circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Select Your Investment</h3>
            <p>Choose between V2+ or V3 units.</p>
          </div>
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-lock-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Secure Your Spot</h3>
                <p>Work with our team to secure financing and apply for government grants.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Secure Your Spot</h3>
                <p>Work with our team to secure financing and apply for government grants.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-lock-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-briefcase-fill circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Invest in Equipment</h3>
            <p>Contribute to the equipment required for your chosen unit.</p>
          </div>
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          {isTabletOrMobile ? (
            <>
              <div className="circle-container">
                <div className="circle">
                  <i className="bi bi-pie-chart-fill circle-icon"></i>
                </div>
              </div>
              <div className="text-container text-right">
                <h3 className="dkgreen">Profit Sharing</h3>
                <p>Earn a share of the profits generated by your investment.</p>
              </div>
            </>
          ) : (
            <>
              <div className="text-container text-left">
                <h3 className="dkgreen">Profit Sharing</h3>
                <p>Earn a share of the profits generated by your investment.</p>
              </div>
              <div className="circle-right-container">
                <div className="circle-right">
                  <i className="bi bi-pie-chart-fill circle-icon"></i>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Vertical and Horizontal Line */}
        {!isTabletOrMobile && (
          <>
            <div className="vertical-line-div">
              <div className="vertical-line right"></div>
            </div>
            <div className="horizontal-line-div">
              <div className="horizontal-line"></div>
            </div>
            <div className="vertical-line-div">
              <div className="vertical-line left"></div>
            </div>
          </>
        )}

        <div className="how-it-works-section">
          <div className="circle-container">
            <div className="circle">
              <i className="bi bi-bar-chart-fill circle-icon"></i>
            </div>
          </div>
          <div className="text-container text-right">
            <h3 className="dkgreen">Monitor Your Earnings</h3>
            <p>Use our app to track your profits and stay updated on the performance of your investment.</p>
          </div>
        </div>

        <div className="section-padding"></div>
      </section>

      {/* Call to Action Section */}
      <div className="darkgreenbreak"></div>
      <div className="cta-block">
        <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Revolutionize the EV charging experience.</h3>
          <Link to="/contact">
  <button className="btn-primary">Contact Us</button>
</Link>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </div>
         </div>
  );
}

export default HowItWorksPage;
