import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Hero from './Hero';
import AboutPage from './AboutPage';
import HowItWorks from './HowItWorks';
import HowItWorksPage from './HowItWorksPage';
import ServicesPage from './ServicesPage';
import TechnologyPage from './TechnologyPage';
import ContactPage from './ContactPage';
import 'aos/dist/aos.css';
import AOS from 'aos';
import AlmaxLogo from './almax.png';
import AlmaxMap from './almaxmap.png';
import AlmaxSplash from './almaxsplash.png';
import AlmaxWallet from './almaxwallet.png';
import LeftGreen from './leftgreen.png';
import Cory from './Cory';
import Gary from './Gary';
import Selena from './Selena';
import Nicko from './Nicko';
import Antoinette from "./Antoinette"
import { Link } from 'react-router-dom';

function App() {
  useEffect(() => {
    AOS.init({ once: true });
    return () => {
      AOS.refreshHard();
    };
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
      <div className="cta-block">
        <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Have questions?</h3>
          <div className="hero-buttons">
            <Link to="/contact">
  <button className="btn-primary">Contact Us</button>
</Link>
          </div>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </div>
             <HowItWorks />
    {/* ALMAX App Section */}
    <section className="app-section">
      <div className="container">
        <img src={AlmaxLogo} alt="Almax Logo" className="app-logo" />
        <p className="description">Manage your EV charging experience with our intuitive mobile app. Locate charging stations, monitor your charge, earn rewards, and much more.</p>
        <div className="app-images">
          <div className="app-image-container">
            <img src={AlmaxMap} alt="Almax Map" className="app-image" />
          </div>
          <div className="app-image-container">
            <img src={AlmaxSplash} alt="Almax Splash" className="app-image" />
          </div>
          <div className="app-image-container">
            <img src={AlmaxWallet} alt="Almax Wallet" className="app-image" />
          </div>
        </div>
        <div className="app-info">
          <div className="app-info-section">
            <h4>Elevated Charging Experience</h4>
            <p>Our app transforms EV charging into a rewarding journey, offering points, rewards, and gaming. With a sleek interface akin to tech giants, we redefine the charging experience.</p>
          </div>
          <div className="app-info-section">
            <h4>Seamless Integration</h4>
            <p>Users can easily earn, exchange, and pay for charging with cryptocurrencies, alongside traditional fiat methods. This flexibility sets a new standard for the industry.</p>
          </div>
          <div className="app-info-section">
            <h4>Personalized Services & Community</h4>
            <p>From scheduling services to tracking progress and accessing exclusive rewards, our app fosters a vibrant EV community, elevating safety and innovation.</p>
          </div>
        </div>
      </div>
    </section>
{/* Call to Action Section */}
<div className="cta-block">
        <div className="left-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
        <div className="cta-container">
          <h3>Revolutionize the EV charging experience.</h3>
          <Link to="/contact">
  <button className="btn-primary">Contact Us</button>
</Link>
        </div>
        <div className="right-padding-container">
        <img src={LeftGreen} alt="LeftGreen" className="left-green" />
        </div>
      </div>

          </>
        } />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/how-it-works" element={<HowItWorksPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/technology" element={<TechnologyPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/Cory" element={<Cory />} />
        <Route path="/Gary" element={<Gary />} />
        <Route path="/Selena" element={<Selena />} />
        <Route path="/Nicko" element={<Nicko />} />
        <Route path="/Antoinette" element={<Antoinette />} />
      </Routes>
    </Router>
  );
}

export default App;
